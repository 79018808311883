@import "@Static/css/common";

$primaryColor: getColorValue(1, (0, 0, 0));
$bgColor: getColorValue(2, (255, 255, 255));
$cardBgColor: rgb(var(--template-page-card-background-host-theme, 0, 0, 0));
$textColor: getColorValue(3, (0, 0, 0));

.root {
    display: flex;
    justify-content: center;
    height: 100%;
    font-size: 0.8rem;
}

.container {
    background-color: $cardBgColor;
    height: 95%;
    width: 100%;
    max-width: min(600px, calc(100vw - 40px));
    padding: 1rem;
    margin: 10px;
    border-radius: 5px;
    box-shadow: #00000014 0px 3px 6px;
}

.quoteContainer {
    display: flex;
    margin-bottom: 1rem;
}

.quote {
    path {
        fill: $primaryColor;
    }
}

.contentContainer {
    position: relative;
    height: 200px;
    overflow-y: hidden;
    color: $textColor;
    font-size: 16px;
    text-align: left;
    white-space: pre-wrap;
    word-break: break-word;
}

.imageContainer {
    cursor: pointer;
    display: flex;
    justify-content: center;
    background: linear-gradient(to right, $primaryColor, $bgColor);
    .image {
        width: 50%;
        height: 100%;
        border-radius: 4px;
        object-fit: cover;
    }
}

.authorContainer {
    display: flex;
    align-items: center;
    img {
        border-radius: 4px;
    }
}

.authorName {
    margin-left: 10px;
    color: $textColor;
}

.readMore {
    padding: 0;
    color: $primaryColor !important;
    font-weight: 600;
    transition: 0.2s ease all;
    &:hover {
        filter: brightness(1.1);
    }
}

.mediaOverlay {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.videoContainer {
    cursor: pointer;
    overflow: hidden;
    iframe,
    video {
        border-radius: 4px;
    }
}

.testimonialHr {
    background-color: rgba(228, 232, 240, 1);
    color: rgba(228, 232, 240, 1);
}

@media (max-width: 768px) {
    .container {
        width: 100%;
        max-width: calc(99vw - 40px);
    }
}
