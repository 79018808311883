@import "@Static/css/common";

$primaryColor: getColorValue(1, (0, 0, 0));
$bgColor: getColorValue(2, (255, 255, 255));
$textColor: getColorValue(3, (0, 0, 0));

.root {
    padding-top: 2rem;
    padding-bottom: 3rem;
}

.container {
    position: relative;
    margin-bottom: 3rem;
    max-width: 1400px;
}

.title {
    text-align: center;
    font-size: 46px;
    line-height: 52px;
    font-weight: bold;
    letter-spacing: 0.74px;
    color: $textColor;
    opacity: 1;
}

.dotMatrix {
    circle {
        fill: getColorValue(2, (255, 255, 255), 0.4);
    }
    position: absolute;
    top: 6rem;
}

.dotListClass {
    bottom: -3rem;
}
.customDot {
    background-color: $primaryColor;
}
@media (max-width: 800px) {
    .title {
        font-size: 28px;
        line-height: 39px;
        font-weight: bold;
        letter-spacing: 0.45px;
        margin-bottom: 40px;
    }
}

@media only screen and (min-width: 901px) {
    .dotMatrix {
        right: calc(((100% - 800px) / 2) - 2rem);
    }
}

@media only screen and (max-width: 900px) {
    .dotMatrix {
        right: -6px;
    }
}
