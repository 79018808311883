// WARNING: This stylesheet is being used in multiple files. Exercise caution while making changes.

// TODO - @aditya: Move this stylesheet inside the <template_name> folder directly and update imports accordingly.

@import "@Static/css/common";

$bgColor: getColorValue(2, (255, 255, 255));
$textColor: getColorValue(3, (0, 0, 0));

.sectionTitle {
    font-size: 46px;
}

.root {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    overflow-y: overlay;
    @extend .themedScrollbar;
    scroll-behavior: smooth;

    color: $textColor;
    background-color: $bgColor;
}
.hr {
    border-width: 0px;
    height: 1.5px;
    width: 90%;
    display: table;
    margin: 0 auto;
}

@media (max-width: 600px) {
    .sectionTitle {
        font-size: 28px;
    }
}
.mediaListContainer {
    margin-bottom: 110px !important;
}
