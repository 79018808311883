@import "@Static/css/common";

$primaryColor: getColorValue(1, (255, 255, 255));
$bgColor: getColorValue(2, (10, 18, 30));
$textColor: getColorValue(3, (255, 255, 255));

.heroSection {
    background-repeat: no-repeat;
    background-blend-mode: overlay;
    background-size: cover;

    padding: 0;
    display: flex;
    align-items: center;
    z-index: 15;
    position: relative;
    // The ratio for this image is 1878w x 4320h
    height: #{(1878 / 4320 * 100) + "vw"};
    margin-top: var(--host-page-navbar-height, 54px);
    max-height: 900px;
}
.shortDescription {
    font-size: 16px;
    line-height: 32px;
    color: $bgColor;
    opacity: 1;
    &.desktopOnly {
        display: initial;
    }
    &.mobileOnly {
        display: none;
    }
}
.heroContent {
    width: 100%;
    max-width: 1240px;
    padding: 0 20px;
    margin: auto;
    text-align: left;
    .title {
        font-size: 46px;
        line-height: 52px;
        font-weight: 900;
        color: $bgColor;
        margin: 20px 0px;
        letter-spacing: 0.74px;
        opacity: 1;
    }
    .bannerCtaContainer {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        .bannerCta {
            background-color: #fff;
            color: $textColor;
            font-size: 17px;
            padding: 10px 40px;
            border-radius: 5px;
            transition: 0.25s ease all;
            display: block;
            &:hover {
                filter: brightness(1.2);
            }
        }
        .heroCtaArrow {
            position: relative;
            margin: 6px 0 -6px 16px;
            .arrowIcon {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }
}

.creatorIntro {
    width: 100%;
    max-width: 1240px;
    text-align: center;
    padding: 0 20px;
    margin: 80px auto;
    color: $textColor;

    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    align-items: center;

    .dotMatrix {
        display: none;
    }

    .introContent {
        position: relative;
        .name {
            font-size: 48px;
            font-weight: bold;
        }
    }
    .profileImg {
        margin: auto;
        width: 30%;
        height: 30%;
        max-width: 400px;
        border-radius: 100000px;
        background-color: #eee;
    }
}

.readMoreButton {
    margin: auto;
}
@media only screen and (max-width: 780px) {
    .dotMatrix {
        circle {
            fill: $primaryColor;
        }
        z-index: 50;
        position: relative;
        float: right;
        top: -40px;
    }

    .creatorIntro {
        flex-direction: column-reverse;
        margin: 48px auto;
        .profileImg {
            width: 100%;
            max-width: 400px;
            margin: auto;
            margin-bottom: 32px;
        }
        .introContent {
            .name {
                font-size: 36px;
            }
        }
    }
    .heroSection {
        min-height: 130px;
    }
    .heroContent {
        margin: 8px 0 16px;
        .bannerCtaContainer {
            flex-direction: column;
            display: none;
            .bannerCta {
                font-size: 15px;
                padding: 8px 24px;
            }

            .heroCtaArrow {
                display: none;
            }
        }
        .title {
            font-size: 24px;
            line-height: 28px;
            margin: 4px 0px 12px;
        }
    }
    .shortDescription {
        line-height: 24px;
        margin: -12px 0 24px;
        text-align: center;
        color: $textColor;
        &.desktopOnly {
            display: none;
        }
        &.mobileOnly {
            display: initial;
        }
    }
}
